import React from "react";
import "../index.css";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-social-links">
          <a href="https://discord.gg/productionrp" title="Discord">
            <img src="/images/discord.svg" alt="Discord" className="social-icon" />
          </a>
          <a href="https://x.com/Production__RP" title="Twitter">
            <img src="/images/twitter.svg" alt="Twitter" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/production__rp" title="Instagram">
            <img src="/images/instagram.svg" alt="Instagram" className="social-icon" />
          </a>
          <a href="https://www.tiktok.com/@production__rp" title="TikTok">
            <img src="/images/tiktok.svg" alt="TikTok" className="social-icon" />
          </a>
          <a href="https://www.threads.net/@production__rp" title="Threads">
            <img src="/images/threads.svg" alt="Threads" className="social-icon" />
          </a>
          <a href="https://www.twitch.tv/productionrp" title="Twitch">
            <img src="/images/twitch.svg" alt="Twitch" className="social-icon" />
          </a>
          <a href="https://www.youtube.com/@productionrp6933" title="YouTube">
            <img src="/images/youtube.svg" alt="YouTube" className="social-icon" />
          </a>
        </div>
        <div className="footer-xavier">
          <a
            href="https://xavierme.us"
            target="_blank"
            rel="noopener noreferrer"
            className="xavier-link"
          >
            <img
              title="XavierMe"
              src="./images/xavier.svg"
              alt="XavierMe"
              className="xavier-image"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
