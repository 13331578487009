import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/RulesPage.css";

const rules = [
  {
    id: "section1",
    title: "Community Respect",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Be Respectful</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
              <span className="max-warning">Max: Permanent Ban</span>]
            </p>
          </div>
          <ul>
            <li>
              Always be mindful of not only your character's choices and actions
              on the server but as well as your behavior with other community
              members within ProductionRP’s platforms, including any PRP
              discords. Everyone has a story to tell, not just yourself.
            </li>
            <li>
              Do not use ProductionRP’s platforms to publicly advertise or
              privately connect with members in order to promote a product,
              service, page, or group{" "}
              <span className="parentheses-background">
                (UNLESS permission granted by the admin team).
              </span>
            </li>
            <li>
              Be mindful and keep in check your OOC reactions while in-game.
              Malding and any other type of excessive anger from an OOC
              standpoint is immersion-breaking and does not respect your fellow
              roleplayers' attempts to stay in character.
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>No Harassment/Discrimination</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 10</span> |{" "}
              <span className="max-warning">Max: Permanent Ban</span>]
            </p>
          </div>
          <ul>
            <li>
              Bullying, discrimination, harassment, and harmful behavior will{" "}
              <span className="highlight-gray">NOT</span> be tolerated.
            </li>
            <li>
              This includes, but is <span className="highlight-gray">NOT</span>{" "}
              limited to, mistreatment and/or discriminatory language used
              regarding a community member’s age, gender, race, disability,
              sexual orientation, or religion. Additionally, do{" "}
              <span className="highlight-gray">NOT</span> use derogatory terms
              towards your friends.
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section2",
    title: "Roleplay Quality",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>No Explicit Content/Roleplay</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 5</span> |{" "}
              <span className="max-warning">Max: 10</span>]
            </p>
          </div>
          <ul>
            <li>
              No derogatory language and/or explicit/NSFW material or images on
              any platform, including in-game and Production Roleplay
              discord(s).
            </li>
            <li>
              The following topics are{" "}
              <span className="highlight-gray">NOT</span> allowed to be
              roleplayed:
              <ul>
                <li>Pregnancy</li>
                <li>Underage</li>
                <li>Suicide/Self-Harm</li>
                <li>Erotic</li>
              </ul>
            </li>
            <li className="notice">
              Any form of sexual harassment or assault will result in a
              permanent removal.
            </li>
            <li>
              Any roleplay involving torture/sensitive issues such as forced
              drug overdose can only occur with consent from all parties
              involved. Consent is continuous and can be withdrawn at any time.
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>No Low-Quality Roleplay</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
              <span className="max-warning">Max: 7</span>]
            </p>
          </div>
          <ul>
            <li>
              Low-quality roleplay is the act of playing outside what is
              reasonable for a character to do within RP. This is to prevent
              unfair advantages, immersion breaking and to promote and encourage
              high-quality roleplay.
              <ul>
                <li>
                  You are required to stay in-character, at all times, while
                  in-game unless special permission is granted by a moderator or
                  admin. This includes avoiding using only in-character
                  terminology{" "}
                  <span className="parentheses-background">
                    (ex. City, Taking a nap, etc.)
                  </span>{" "}
                  and <span className="highlight-gray">NOT</span> reading
                  OOC/Help chat out loud in character.
                </li>
                <li>
                  You are expected to roleplay hostile situations to the best of
                  your ability in order to encourage prolonged creative
                  interactions limiting the escalation of immediate violence to
                  end a roleplay scene. Drama on social media isn’t enough to
                  incite immediate violent PVP.
                </li>
                <li>
                  Repeated retaliatory violence/attacks against someone, beyond
                  a single time is <span className="highlight-gray">NOT</span>{" "}
                  allowed. Attacking, robbing, or harming a character and/or
                  their assets without giving them a chance to retaliate is seen
                  as excessive. Being arrested and charged appropriately solely
                  isn’t enough to retaliate against law enforcement.
                </li>
                <li>
                  Pocket wiping{" "}
                  <span className="parentheses-background">
                    (stealing every single thing from a victim's pockets)
                  </span>{" "}
                  is <span className="highlight-gray">NOT</span> allowed.
                </li>
                <li>
                  Emoting/Dancing over a downed player as a taunt is prohibited.
                </li>
                <li>
                  You cannot use OOC terminology{" "}
                  <span className="parentheses-background">
                    (ex. “W” cop/crim, “L” cop/crim)
                  </span>
                  , “SOP Check” or “RP check” other players while in-character.
                </li>
                <li>
                  Proper usage of /me’s and /status as you{" "}
                  <span className="highlight-gray">CANNOT</span> use it to
                  convey thoughts or feelings. This is to assist fellow
                  roleplayers with what your character can be seen doing,
                  realistically.
                </li>
                <li>
                  Cop Baiting is provoking a law enforcement response for no
                  story or reason is <span className="highlight-gray">NOT</span>{" "}
                  allowed on all counts of it.
                </li>
                <li>
                  Characters should suit a real, modern day setting and must be
                  over the age of 18. They should be developed thoroughly and
                  thought-out with motives and reasons for their actions.
                  Characters must have a suitable, appropriate name that doesn’t
                  mock other public/pop-culture figures and/or community
                  members. In order to have those names, you must{" "}
                  <span className="highlight-gray">NOT</span> roleplay as those
                  real life figures.
                </li>
                <ul>
                  <li>
                    1. Visible body armor/plate carriers and masks 24/7 as a
                    permanent appearance except medical masks{" "}
                    <span className="parentheses-background">
                      (with involvement of medical roleplay)
                    </span>{" "}
                    are <span className="highlight-gray">NOT</span> allowed.
                  </li>
                </ul>
                <li>
                  You are required to provide medical roleplay when injured. You
                  may do so by emoting through /me’s, /do’s, or describing the
                  pain/injuries verbally. This{" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1dFzSWC0-dP58vyqhITB-jpThsF0cOXamkhTZzXdfZN8/edit?gid=0#gid=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide
                  </a>{" "}
                  can help assist you if you struggle with this aspect of
                  roleplay.
                </li>
                <li>
                  Purposely pleading “Not Guilty” and{" "}
                  <span className="highlight-gray">NOT</span> communicating or
                  following up with the DOJ.
                </li>
                <li>
                  Players should <span className="highlight-gray">NOT</span> be
                  inserting themselves into ongoing situations or shootouts
                  involving other groups or between other groups and cops. This
                  includes not limited to:
                  <ul>
                    <li>
                      Interfering with or trying to hijack a heist/job once cops
                      have arrived.
                    </li>
                    <li>
                      If there is a shootout between 2 crews or a shootout
                      between a crew and cops.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section3",
    title: "Game Mechanics",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>No Exploiting</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 5</span> |{" "}
              <span className="max-warning">Max: Permanent Ban</span>]
            </p>
          </div>
          <ul>
            <li>
              Any usage of hacks/cheats, certain third-party software, or
              exploiting is <span className="highlight-gray">NOT</span>{" "}
              permitted including packs that have disruptions and/or
              advantage-gaining aspects.
            </li>
            <li>
              Any bug that is discovered and{" "}
              <span className="highlight-gray">NOT</span> immediately reported{" "}
              <span className="parentheses-background">
                (via Production’s Bug Reports)
              </span>{" "}
              while continuing to use it for unfair advantages will be
              considered exploiting.
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>No Fail Driving</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
              <span className="max-warning">Max: 6</span>]
            </p>
          </div>
          <ul>
            <li>
              Fail driving is the act of driving or flying any type of vehicle
              or aircraft outside what is reasonable to do within both your
              character and overall roleplay. This is to prevent unfair
              advantages and the ruining of immersion.
              <ul>
                <li>
                  Performing{" "}
                  <span className="parentheses-background">“rat strats”</span>{" "}
                  such as taking a vehicle up a staircase or ocean dumping
                  vehicles within the first few minutes of a chase resulting in
                  minimal RP for the opposing players involved.
                </li>
                <li>
                  Taking high jumps that your character would realistically
                  become incapacitated from.{" "}
                  <span className="parentheses-background">
                    (ex. Jump from La Puerta on the ramp down to Del Perro Fwy)
                  </span>
                  . An example of a jump that would be okay is from San Andreas
                  upper to San Andreas lower in the Downtown Pillbox area.
                </li>
                <li>
                  No supercars or imports are allowed at any mechanical based
                  petty crime such as Vangelicos, ATMs, Home Invasions, CDS/Drug
                  Calls, Warehouse, and Store Robberies.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section4",
    title: "Roleplay Integrity and Fairness",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>No Powergaming</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 5</span> |{" "}
              <span className="max-warning">Max: 7</span>]
            </p>
          </div>
          <ul>
            <li>
              Powergaming is the act of fabricating an advantage that couldn’t
              be reasonably obtained in roleplay, or abusing a known mechanic in
              a harmful manner. This is to prevent the mindset of “just because
              you can, doesn’t mean you should.”
              <ul>
                <li>
                  You <span className="highlight-gray">cannot</span> force
                  another character to give access or ownership of personal
                  belongings, make decisions for their character through /me’s
                  and /do’s or spam abuse emotes to prevent fleeing.
                </li>
                <li>
                  It is prohibited for any crime{" "}
                  <span className="parentheses-background">
                    (mechanical and roleplay-based)
                  </span>{" "}
                  to take place 30 minutes before or after restarts and
                  unexpected server crashes, with the exception of escalation
                  within a scene if it began before the 30-minute timer.
                </li>
                <li>
                  Abusing F5 to obtain player locations and/or using information
                  obtained through lack of audio inclusion is prohibited.
                </li>
                <li>
                  In order to legitimately use picture(s) or footage
                  in-character of a scene, you must ensure you follow one of the
                  standards below:
                  <ul>
                    <li>Wearing a visible body cam on your person</li>
                    <li>Using an emote to hold your phone out</li>
                    <li>Holding a camera</li>
                    <li>Having a CCTV camera in the vicinity of the scene</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>No Metagaming</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 4</span> |{" "}
              <span className="max-warning">Max: 10</span>]
            </p>
          </div>
          <ul>
            <li>
              This is the use of information obtained out of character, that
              should not be known in character and applying it in roleplay.
              <ul>
                <li>
                  Streamsniping, which is watching a livestream of any player in
                  the server while also being in the server yourself, is not
                  allowed.
                </li>
                <li>
                  Using any software other than the in-game radio or phone to
                  communicate with other characters at a distance as well as
                  contacting players requesting their presence on the server for
                  combative/hostile roleplay advantage is prohibited.
                </li>
                <li>
                  You are not allowed to identify someone’s character solely
                  based on their voice.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section7",
    title: "Restricted Areas",
    content: (
      <div className="section-block">
        <div className="section-header">
          <h2>Respect Restricted Areas</h2>
          <p className="violation">
            VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
            <span className="max-warning">Max: 5</span>]
          </p>
        </div>
        <ul>
          <li>
            Certain areas of the map are considered highly secure and constantly
            monitored, though they may not always have players there to guard or
            respond. This is to prevent characters letting crimes occur in
            locations where it realistically should be not possible in most
            cases.
          </li>
          <li>
            Prison breaks require at least four DOC officers on the server. Only
            exception is if admin permission is given.
          </li>
          <li>
            Entering into Los Santos International Airport gated runways and
            buildings without licensing or Government employment is strictly
            prohibited.
          </li>
          <li>
            Tampering with or stealing any vehicle parked at the premises of
            Hospitals, Fire Stations, Police Stations, or City Hall is not
            allowed.
          </li>
          <li>
            Unless a character actively flees into a clothing store, it is
            prohibited to rob, kidnap, or hurt another while inside said store.
          </li>
          <li>
            Committing large-scale crimes{" "}
            <span className="parentheses-background">
              (Attempted Murder, Assault With A Deadly, etc.)
            </span>{" "}
            inside the premises of any State or Federal Government Buildings are
            not allowed without prior admin approval. Committing these types of
            crimes outside the premises are allowed with sufficient roleplay
            reasoning, planning and that it makes sense for your character to
            participate in the following knowing the potential risks. Bleeter
            beef is <span className="highlight-gray">NOT</span> a reasonable
            motive.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: "section8",
    title: "Logging and Camping",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>No Roleplay/Combat Logging</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 5</span> |{" "}
              <span className="max-warning">Max: 7</span>]
            </p>
          </div>
          <ul>
            <li>
              This is the act of intentionally disconnecting from the server
              during or leading up to an active roleplay/combat scene to avoid
              an interaction or consequence. Examples of breaking this rule
              could be, but are not limited to:
              <ul>
                <li>
                  Not remaining in the server for at least 10 minutes after the
                  situation has ended.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>No Camping</h2>
            <p className="violation">
              VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
              <span className="max-warning">Max: 5</span>]
            </p>
          </div>
          <ul>
            <li>
              Intentionally targeting known spawns, public areas, or criminal
              locations, knowing that people will be unable to fight back, with
              the intent to rob, assault, kidnap, etc is not allowed. This
              includes camping player housing to enter as they exit before they
              can lock the door.
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section10",
    title: "Group Restrictions, Reactions and Escalations",
    content: (
      <div className="section-block">
        <div className="section-header">
          <h2>Group Restrictions, Reactions and Escalations</h2>
          <p className="violation">
            VIOLATION: [<span className="min-warning">Min: 3</span> |{" "}
            <span className="max-warning">Max: 5</span>]
          </p>
        </div>
        <div>
          <table className="group-limits-table">
            <caption>Group Limits</caption>
            <thead>
              <tr>
                <th>Activity</th>
                <th>Criminals</th>
                <th>Cops</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  All Mechanical-Based Crimes with Loot Pools{" "}
                  <span className="max-warning">EXCEPT</span> Bobcat, PSB, Humane and Paleto
                </td>
                <td>4</td>
                <td>6**</td>
              </tr>
              <tr>
                <td>PVP*, Bobcat, PSB, Humane and Paleto</td>
                <td>6</td>
                <td>8**</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul>
          <li>
            <span className="highlight-link">
              *There are no protected gang territories that allow unlimited
              numbers for groups to run to before or during an active gun fight.
            </span>{" "}
            If you’re in conflict, you need to remember to value your life if
            you’re approaching a gang territory and there’s already a large
            group presence.
          </li>
          <li>
          <span className="highlight-link">
            **Once a 10-13A is activated, you have a 5-minute window before the cop limit is removed.
          </span>{" "}
          Your focus should be on fearing the cops and not treating them as loot boxes.{" "}
          <span className="parentheses-background">
            Note, Air-1 counts as <span className="highlight-link">one unit</span> and K9 counts as <span className="highlight-link">two units</span>.
          </span>
        </li>
          <li>
            For general scenarios{" "}
            <span className="parentheses-background">
              (not a robbery/heist)
            </span>
            , police are limited to 8 officers per interaction prior to a 10-13A
            being activated.
          </li>
          <li>
            Directly involved criminals and law enforcement officers involved
            with scenarios are not allowed to swap out with other players at any
            point. Involvement includes retrieving and managing hostages,
            hacking, smashing/grabbing, negotiations, getaway driving, scene
            clean-up after the chase begins, etc.
          </li>
          <li>
            During an active scene, you are not allowed to return if you have
            repaired your vehicle or gone to the hospital. Cops are allowed to
            return for scene clean-up once the scenario has ended.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: "section11",
    title: "ESD Corruption",
    content: (
      <div className="section-block">
        <div className="section-header">
          <h2>No Unapproved ESD Corruption</h2>
          <p className="violation">
            VIOLATION: [<span className="min-warning">Min: 5</span> |{" "}
            <span className="max-warning">Max: 5</span>]
          </p>
        </div>
        <ul>
          <li>
            Corruption is any criminal or immoral activity committed on or off
            duty by someone employed within Emergency Services or the Department
            of Justice. At any point in your character’s story{" "}
            <span className="parentheses-background">
              (who is an emergency services/government employee)
            </span>{" "}
            have come to a point where corruption is the next logical step, you
            are to fill out an ESD Corruption Form from the{" "}
            <a
              href="https://discord.com/channels/888066725923418142/888147848582037584"
              target="_blank"
              rel="noopener noreferrer"
            >
              #support-tickets
            </a>{" "}
            channel within the Discord. You must have an in-depth and high
            quality backstory as your reasons for corruption. Failure to provide
            reasons or maintain a high-quality backstory will result in a denial
            to be corrupt.
          </li>
          <li>
            Approvals are discussed and provided by department managers.
            Performing corruption without approval will result in warning points
            and immediate termination from your department.
          </li>
        </ul>
      </div>
    ),
  },
];

const updateHistory = [
  { date: "August 9th, 2024", description: "Complete Rules Update." },
];

const RulesPage = () => {
  const [activeSection, setActiveSection] = useState("welcome");
  const [searchQuery, setSearchQuery] = useState("");
  const [showHistory, setShowHistory] = useState(false);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const showSection = (sectionId) => {
    setActiveSection(sectionId);
  };

  const filterContent = (content) => {
    if (typeof content === "string") {
      return content.toLowerCase().includes(searchQuery.toLowerCase());
    } else if (React.isValidElement(content)) {
      return React.Children.toArray(content.props.children).some((child) =>
        filterContent(child)
      );
    }
    return false;
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="rules-highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const highlightedContent = (content) => {
    if (typeof content === "string") {
      return highlightText(content, searchQuery);
    } else if (React.isValidElement(content)) {
      return React.cloneElement(content, {
        children: React.Children.map(content.props.children, (child) =>
          highlightedContent(child)
        ),
      });
    }
    return content;
  };

  const filteredSections = rules.filter(
    (rule) =>
      rule.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      filterContent(rule.content)
  );

  return (
    <div className="app-container">
      <Nav />
      <div className="content">
        <div className="update-date">
          <p>
            View the{" "}
            <span
              className="date"
              onClick={toggleHistory}
              style={{ cursor: "pointer" }}
            >
              Changelog
            </span>
          </p>
          {showHistory && (
            <div className="history">
              <h3>Changelog</h3>
              <ul>
                {updateHistory.map((entry, index) => (
                  <li key={index}>
                    <strong>{entry.date}:</strong> {entry.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="content-section">
          <div className="sections-menu">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search rules..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            <select onChange={(e) => showSection(e.target.value)}>
              {filteredSections.map((rule) => (
                <option key={rule.id} value={rule.id}>
                  {rule.title}
                </option>
              ))}
            </select>
            {filteredSections.map((rule) => (
              <button
                key={rule.id}
                className={`section-btn ${
                  activeSection === rule.id ? "active" : ""
                }`}
                onClick={() => showSection(rule.id)}
              >
                {rule.title}
              </button>
            ))}
          </div>
          <div className="section-content">
            {activeSection === "welcome" && (
              <div id="welcome" className="section-details active">
                <div className="section-block">
                  <h2>Welcome to ProductionRP Rules!</h2>
                  <p>
                    Welcome to ProductionRP! We are dedicated to creating an
                    exciting and immersive roleplay experience for everyone. Our
                    goal is to provide a positive environment, and our rules are
                    crafted to support this mission. We love hearing from our
                    community and are always ready to adjust our rules to meet
                    new challenges. Your feedback is invaluable to us!
                  </p>
                </div>
              </div>
            )}
            {filteredSections.map((rule) => (
              <div
                key={rule.id}
                id={rule.id}
                className={`section-details ${
                  activeSection === rule.id ? "active" : ""
                }`}
              >
                {highlightedContent(rule.content)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RulesPage;
