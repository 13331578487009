import React, { useEffect, useState } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import "../index.css";

const AboutSection = () => {
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.3,
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    //"/images/bcso.png",
    //"/images/default1.png",
    //"/images/default2.png",
    //"/images/sasp.png",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <section
      id="about"
      className={`about-section scroll-animation ${
        isIntersecting ? "show" : ""
      }`}
      ref={ref}
    >
      <div className="about-container">
        <div className="about-text">
          <div className="about-line"></div>
          <h2>
            ABOUT THE <br /> <span className="highlight">PRODUCTION</span>{" "}
            FAMILY
          </h2>
          <p>
            ProductionRP is an expanding community on our FiveM server, focused
            on delivering diverse and high-quality roleplay experiences. Our
            goal is to create a user-friendly environment with unique features
            that cater to both beginners and advanced players, supported by a
            dynamic and accessible staff team. This includes Support Staff,
            Moderators, Managers, Developers, and Admins who evolve with the
            community.
          </p>
          <p>
            We maintain a strict no-tolerance policy for toxicity, ensuring that
            discussions are healthy and moderated to prevent disputes. We
            emphasize open communication, allowing players to actively
            participate in shaping the community through feedback and ideas,
            handled with transparency by our staff. Our server supports all
            player roles—civilians, criminals, business owners, and emergency
            services—providing a balanced and engaging roleplay environment for
            everyone to explore comprehensive character development.
          </p>
          <div className="about-button-group">
            <button
              className="read-more-button"
              onClick={() => (window.location.href = "/about")}
            >
              READ MORE
            </button>
            <button
              className="read-more-button"
              onClick={() => (window.location.href = "/community")}
            >
              COMMUNITY
            </button>
          </div>
        </div>
        <div className="about-img">
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`About ${index + 1}`}
              className={`about-pic ${
                index === currentImageIndex ? "active" : ""
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
